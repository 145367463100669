<template>
  <div class="wap-notice-detail">
    <headerBar :title="$t('xiao-xi-xiang-qing')" @back="back"></headerBar>
    <div class="main">
      <div class="title">{{ detailData.Title }}</div>
      <div class="date">{{ detailData.CreatedAt }}</div>
      <div class="content" v-html="detailData.Content"></div>
    </div>
  </div>
</template>
<script>
import headerBar from '@/components/header'
export default {
  components: {
    headerBar
  },
  data() {
    return {
      emptyUrl: require('@/assets/imgs/emtpy.svg'),
      detailData: {}
    }
  },
  mounted() {
    let data = sessionStorage.getItem('noticeDetail')
    if (data) {
      this.detailData = JSON.parse(data)
    } else {
      this.back()
    }
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    init() {
      getNoticeList({
        current: this.page.current,
        pageSize: this.page.size
      }).then((res) => {
        this.noticeList = res.data.Items
        this.page.total = res.data.Pagination.totalRecords
      })
    },
    changeTab(i) {
      this.tabIndex = i
    },
    currentChange(page) {
      this.page.current = page
      this.init()
    },
    toDetail(data) {
      sessionStorage.setItem('noticeDetail', JSON.stringify(data))
      this.$router.push({
        name: 'noticeDetail'
      })
    }
  }
}
</script>